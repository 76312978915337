import React from 'react'

const placeHolderComponent = () => {
  return (
    <div>
      This Div Is A PlaceHolder.
    </div>
  )
}

export default placeHolderComponent
